@import url('https://fonts.googleapis.com/css2?family=Heebo&family=Kanit:wght@300&display=swap');

* {
  box-sizing: border-box;
}

h5 {
  margin: 0;
}

.nav {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #2E0249;
  height: 10vh;
  display: flex;
  box-shadow: 20px pink;
  font-family: 'Kanit', sans-serif;
  color: #F806CC;
  font-size: 1.5rem;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, .8);
  position: relative;
  z-index: 10;
}

.logoImg {
  height: 60px;
}

.menuEl:hover {
  color: white
}

.m-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.pt-1 {
  padding-top: 1rem;
}

.logoSide {
  display: flex;
  flex: 5;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-left: 2rem;
}

.buttonSide {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 5;
  align-items: center;
  justify-content: flex-end;
  margin-right: 7rem;
}

.menuList {
  list-style: none;
  display: flex;
}

.menuEl {
  margin-left: 3rem;
  cursor: pointer;
}


.footer {
  display: flex;
  bottom: 0px;
  width: 100%;
  height: 15vh;
  background-color: #2E0249;
  box-shadow: 5px 2px 2px 2px rgba(0, 0, 0, .8);
  margin: 0;
}

.footercol1 {
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.footercol2 {
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.footercol3 {
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.footerMenu {
  list-style: none;
  font-family: 'Kanit', sans-serif;
  color: #F806CC;
  font-size: 1.5rem;
  cursor: pointer;
}

.text-center {
  text-align: center;
  font-family: 'Kanit', sans-serif;
  font-size: 1.5rem;
}

.card-text {
  text-align: center;
  font-family: 'Kanit', sans-serif;
}

hr {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.columnFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: #2E0249;
}

.pwrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.progress {
  width: 70%;
  height: 35px;
  margin: 10px;
  border-radius: 20px;
  background: #f9f9f9;
}

.bar {
  border-radius: 20px;
  height: 100%;
  transition: width;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.36, .55, .63, .48);
}

.shadow {
  /* 25 50 */
  box-shadow: 0px 45px 50px rgba(0, 0, 0, 0.25);
}

.bars {
  background-color: #F806CC;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238fe1e7' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}

.cardwrap {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* CSS */
.button-33 {
  background-color: #F806CC;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  transition: all 250ms;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family: 'Kanit', sans-serif;
  font-size: 1.4rem;
  padding: 2px 9px;
}

.button-33:hover {
  color: white;
}

.card {
  width: 40%;
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 45px 50px rgba(0, 0, 0, 0.25);
}

.currencyInput {
  width: 90%;
  height: auto;
  font-size: 2rem;
  border: none;
  border-bottom: 1px solid black;
  font-weight: 400;
  text-align: center;
}

.wbg {
  background-color: white;
  border-radius: 15px;
}

.currencyInput:focus {
  outline: none;
}

.currencyInput:disabled {
  background-color: white;
}

.buyButton {
  margin-top: 1rem;
  display: flex;
  margin: 0;
  padding: 0;
  background-color: #2E0249;
  width: 40%;
  height: 9vh;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #F806CC;
  cursor: pointer;
  font-size: 1.5rem;
  justify-content: center;
  font-family: 'Kanit', sans-serif;
  align-items: center;
  transition: 1.2s;
}

.buyButton:hover {
  background-color: #4f1f6e;
  color: #f3b2e7;

}

.buttonWrap {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.ref {
  width: 100%;
  margin-top: 4rem;
}

.refGif {
  cursor: pointer;
}

.modalClose {
  font-size: 2rem;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
  font-family: 'Kanit', sans-serif;
}

.modalClose:hover {
  color: #2E0249;
  transition: 1s;
}

.inputWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.refInput {
  width: 100%;
  height: auto;
  font-size: 2rem;
  border: none;
  border-bottom: 1px solid black;
  font-weight: 400;
  text-align: center;
  background-color: black;
  cursor: pointer;
  color: white;
}

.modal {
  background-color: #F806CC;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 45vh;
  border-radius: 15px;
  color: white;
  border: 2px solid black;
}

.refFlexWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ptextWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Kanit', sans-serif;
  font-size: 1.5rem;
}

.backBorderPattern {
  position: absolute;
  top: 200px;
  left: 260px;
  width: 300px;
  height: 300px;
  background: #f09;
  background-image: linear-gradient(45deg, #3023AE 0%, #f09 100%);
  border-radius: 28% 72% 79% 21% / 30% 17% 83% 70%;
  z-index: -1;
}

.backBorderPattern1 {
  position: absolute;
  bottom: 200px;
  right: 260px;
  width: 150px;
  height: 150px;
  background: #f09;
  background-image: linear-gradient(45deg, #3023AE 0%, #f09 100%);
  border-radius: 28% 72% 79% 21% / 30% 17% 83% 70%;
  z-index: -1;
}

.backBorderPattern2 {
  position: absolute;
  bottom: 300px;
  right: 400px;
  width: 225px;
  height: 225px;
  background: #f09;
  background-image: linear-gradient(45deg, #3023AE 0%, #f09 100%);
  border-radius: 28% 72% 79% 21% / 30% 17% 83% 70%;
  z-index: -1;
}

.backBorderPattern3 {
  position: absolute;
  bottom: 300px;
  left: 400px;
  width: 120px;
  height: 120px;
  background: #f09;
  background-image: linear-gradient(45deg, #3023AE 0%, #f09 100%);
  border-radius: 28% 72% 79% 21% / 30% 17% 83% 70%;
  z-index: -1;
}

.backBorderPattern4 {
  position: absolute;
  bottom: 100px;
  left: 120px;
  width: 240px;
  height: 240px;
  background: #f09;
  background-image: linear-gradient(45deg, #3023AE 0%, #f09 100%);
  border-radius: 28% 72% 79% 21% / 30% 17% 83% 70%;
  z-index: -1;
}

.backBorderPattern5 {
  position: absolute;
  top: 140px;
  right: 120px;
  width: 278px;
  height: 278px;
  background: #f09;
  background-image: linear-gradient(45deg, #3023AE 0%, #f09 100%);
  border-radius: 28% 72% 79% 21% / 30% 17% 83% 70%;
  z-index: -1;
}


@media only screen and (max-width: 600px) {
  .nav {
    font-size: 1rem;
  }

  .menuEl {
    margin-left: 1rem;
  }

  .buttonSide {
    margin-right: 1rem;
  }

  .progress {
    height: 23px;
  }

  .text-center {
    font-size: 1.3rem;
  }

  .card {
    width: 70%;
  }

  .buyButton {

    height: 12vh;
    width: 70%;

  }

  .progress {
    width: 90%;
  }

  .button-33 {
    font-size: 1rem;
  }

  .currencyInput {
    font-size: 1.3rem;
  }

  .ref {
    margin-top: 2rem;
  }

  .footer {
    bottom: 0;
    height: 23vh;
  }

  .backBorderPattern {
    display: none;
  }

  .backBorderPattern1 {
    position: absolute;
    bottom: 200px;
    right: 260px;
    width: 150px;
    height: 150px;
    background: #f09;
    background-image: linear-gradient(45deg, #3023AE 0%, #f09 100%);
    border-radius: 28% 72% 79% 21% / 30% 17% 83% 70%;
    z-index: -1;
  }

  .backBorderPattern2 {
    position: absolute;
    bottom: 300px;
    right: 400px;
    width: 225px;
    height: 225px;
    background: #f09;
    background-image: linear-gradient(45deg, #3023AE 0%, #f09 100%);
    border-radius: 28% 72% 79% 21% / 30% 17% 83% 70%;
    z-index: -1;
  }

  .backBorderPattern3 {
    display: none;
  }

  .backBorderPattern4 {
    position: absolute;
    bottom: 100px;
    left: 120px;
    width: 240px;
    height: 240px;
    background: #f09;
    background-image: linear-gradient(45deg, #3023AE 0%, #f09 100%);
    border-radius: 28% 72% 79% 21% / 30% 17% 83% 70%;
    z-index: -1;
  }

  .backBorderPattern5 {
    display: none;
  }

  .footerMenu {

    font-size: 1rem;
  }


}